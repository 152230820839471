import { Dispatch, SetStateAction } from "react";
import { t } from "i18next";
import { mdiGoogle } from "@mdi/js";
import HButton from "../common/hbutton/HButton";
import { useSignInGoogle } from "../../hooks";
import { GENERIC_STYLES } from "../../constants";

export const LoginGoogle = ({
  setError,
  handlePushLoginEvent,
}: {
  setError: Dispatch<SetStateAction<string>>;
  handlePushLoginEvent: (customerId: string) => void;
}) => {
  const { handleAuthGoogle } = useSignInGoogle({
    pushEventLogin: handlePushLoginEvent,
    setError,
  });

  return (
    <HButton
      type="button"
      name="button login google"
      containerStyles="w-full"
      className={`${GENERIC_STYLES.outline} gap-2`}
      icon={mdiGoogle}
      text={t("global.login.btnGoogle")}
      onClick={handleAuthGoogle}
    />
  );
};
