
import { ICustomerStore, StateOrders } from "../../../../interfaces";
import { IOrderEsim } from "../../../../interfaces/global/orderEsimInterface";
import { formatMBytes } from "../../../../services/math";
import { useCustomerStore } from "../../../../store/customerStore";
import { daysTotalLimited } from "../../../../utils/isUnlimited";
import { FlagCountry } from "../../../../components/common/flag/FlagCountry";
import { HistoryPlans } from "./HistoryPlans";
import people from "../../../../assets/details/chill.svg";
import cloud from "../../../../assets/details/cloud.png";
import { CallLogDetail } from "./CallLogDetail";
import { TypePlan } from "./TypePlan";
import { useGetHistoryItems } from "../../../../hooks/useGetHistoryItems";
import { Trans, useTranslation } from "react-i18next";
import { useScreen } from "../../../../hooks/useScreen";

interface CardStateActiveDetailsProps {
  cardDetails: IOrderEsim;
}
export const CardState = ({ cardDetails }: CardStateActiveDetailsProps) => {
  const { t } = useTranslation();
  const { language } = useCustomerStore((store: ICustomerStore) => store);
  const screen = useScreen();

  const colorStatus =
    cardDetails.status === "ACTIVE"
      ? { color: "text-semantic-info-600", bgColor: "bg-semantic-info-100" }
      : { color: "text-semantic-500", bgColor: "bg-semantic-100" };

  const isLimited: boolean | undefined | null =
    cardDetails.consumedData?.isUnlimited;
  const totalDataMbNameBytes: string = formatMBytes(
    Number(cardDetails.consumedData?.totalDataMb),
    0
  );
  const percentNameBytes: string = formatMBytes(
    Number(cardDetails.consumedData?.remainingDataMb)
  );

  const daysTotal: string = daysTotalLimited(cardDetails, language) || "null";

  const activationDate = cardDetails.activation_date?.split("T")[0] || "";
  const desactivationDate = cardDetails.deactivation_date?.split("T")[0] || "";

  const { items, loading, mapItemsFromJson } = useGetHistoryItems(
    cardDetails.id
  );

  return (
    <div className="relative w-full lg:w-[496px] z-0 mb-[30px]">
      <div className="flex justify-between items-start w-full">
        <div className="flex flex-col gap-5">
          <FlagCountry
            state={cardDetails.status as StateOrders}
            flagImg={cardDetails.flagUrl || ''}
          />
          <div className="flex flex-col gap-1 w-[199px] md:w-auto">
            <h2 className="text-[26px] font-bold whitespace-normal break-words break-all">
              {cardDetails.destination[language] ||
                cardDetails.destination["en"]}
            </h2>
            <p className="text-[16px] font-normal text-[#292B2E]">
              {cardDetails.boundle[language] || cardDetails.boundle["en"]}
            </p>
            <p className="text-[14px] font-normal text-[#56575A]">{`${t(
              "global.customerV2.details.active.textStartDate"
            )} ${activationDate} - ${t(
              "global.customerV2.details.active.textEndDate"
            )} ${desactivationDate} `}</p>
          </div>
        </div>

        <TypePlan
          isLimited={isLimited}
          percentNameBytes={percentNameBytes}
          totalDataMbNameBytes={totalDataMbNameBytes}
          usedDataPercent={cardDetails.consumedData?.usedDataPercent || ""}
          remainingDays={cardDetails.consumedData?.remainingDays || 0}
          daysTotal={daysTotal}
          usedData={Number(cardDetails?.consumedData?.usedData)}
        />
      </div>

      {cardDetails.msisdn ? (
        <CallLogDetail numberPhone={cardDetails.msisdn} />
      ) : (
        <></>
      )}

      {items.bundles.length === 1 ||
        (items.bundles.length === 0 && (
          <div className="relative bg-gradient-to-t from-[#E0F4FD] to-[#F2FDEB] w-full h-[86px] rounded-xl px-3 mt-5">
            <div className="flex flex-row justify-between items-center md:gap-4 h-full">
              <img className="h-full" src={people} alt="people_banner" />
              <div className="flex flex-col ">
                {screen > 320 && (
                  <p className="relative z-[1] w-[172px] md:w-[244px] text-center text-sm md:text-base text-neutral-charcoal font-normal">
                    <Trans
                      i18nKey="global.customerV2.details.active.banners.bannerAddMoreData"
                      components={{
                        b: <b />,
                      }}
                    />
                  </p>
                )}

                <img
                  className="absolute top-2 z-0 -right-4 "
                  src={cloud}
                  alt="cloud_banner"
                />
              </div>
            </div>
          </div>
        ))}

      {items.bundles.length > 1 && cardDetails?.consumedData && (
        <>
          <hr className="mt-[24px]" />
          {!loading && (
            <HistoryPlans
              expiredDate={desactivationDate}
              isUnlimited={cardDetails?.consumedData.isUnlimited}
              items={mapItemsFromJson(
                items,
                cardDetails?.deactivation_date || ""
              )}
              remainingDays={cardDetails?.consumedData.remainingDays}
            />
          )}
        </>
      )}
    </div>
  );
};
