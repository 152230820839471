import axios, { AxiosResponse } from "axios";
import { Methods } from "../interfaces";
import { getToken } from "../utils";

export const makeApiCall = async <T>(
  method: Methods,
  route: string,
  body?: any,
  signal?: AbortSignal
):Promise<AxiosResponse<T>> => {
  const publicUrls = ["/user/signIn", "/user/signUp", "/graphql"];

  const token = getToken() ?? "";

  let headers: { "Content-Type": string } & { token?: string } = {
    "Content-Type": "application/json",
  };

  if (!publicUrls.some((element) => route.includes(element))) {
    headers = { ...headers, token };
  }

  const config = {
    headers,
    signal,
    data: body ? JSON.stringify(body) : undefined,
  };

  return await axios({ ...config, method, url: route });
};
