import { useState } from "react";
import { useParams } from "react-router-dom";
import { userProfileAdapter } from "../adapters";
import { useCustomerStore } from "../store/customerStore";
import { getUserProfile, postUpdatedUserProfile } from "../services";
import { getAndDecryptLocalStorageData } from "../utils";
import {
  CustomerProfile,
  DataUser,
  ICustomerStore,
  IUseGetCustomerProfile,
} from "../interfaces";
import { initialCustomerProfile } from "../constants";

const STATUS_LOAD_DATA_INITIAL = {
  loading: true,
  isError: false,
  isSuccess: false,
};
export const 
useGetCustomerProfile = (): IUseGetCustomerProfile => {
  const [statusLoadData, setStatusLoadData] = useState(
    STATUS_LOAD_DATA_INITIAL
  );
  const { loading, isError, isSuccess } = statusLoadData;

  const { setProfile, profile, setEmailLocalStorage, customerId } =
    useCustomerStore((store: ICustomerStore) => store);
  const params = useParams();

  const id = customerId || params.customerId;

  const saveChangesProfile = async (data: DataUser) => {
    setStatusLoadData({ ...statusLoadData, ...STATUS_LOAD_DATA_INITIAL });

    const {
      data: response,
      loading,
      status,
      isCompleted,
      isError,
    } = await postUpdatedUserProfile(id || "", data);

    if (status === 200) {
      const result = userProfileAdapter(response);
      profile === null
        ? setProfile(initialCustomerProfile)
        : setProfile({
            ...profile,
            firstName: result?.firstName || "",
            lastName: result?.lastName || "",
          });

      setStatusLoadData({
        ...statusLoadData,
        loading,
        isSuccess: isCompleted!,
      });

      return;
    }
    setStatusLoadData({
      ...statusLoadData,
      loading,
      isError: isError!,
    });
    setProfile(response);
  };

  const loadProfileData = async () => {
    setStatusLoadData({ ...statusLoadData, ...STATUS_LOAD_DATA_INITIAL });

    const { data, loading, status } = await getUserProfile(id || "");

    if (status === 200) {
      const result = userProfileAdapter(data);
      setProfile(result as CustomerProfile);

      if (!getAndDecryptLocalStorageData("email")) {
        setEmailLocalStorage(data?.emails[0]?.address || "");
      }
      setStatusLoadData({ ...statusLoadData, loading });

      return;
    }
    setStatusLoadData({ ...statusLoadData, loading });
    setProfile(data);
  };

  return { loadProfileData, saveChangesProfile, loading, isError, isSuccess };
};
