import { useState, useEffect, ChangeEvent } from "react";
import { FloatingLabelInput } from "../../components/input/FloatingLabelInput";
import HButton from "../../components/common/hbutton/HButton";
import { mdiApple, mdiFaceAgent, mdiAlertCircleOutline } from "@mdi/js";
import { LanguageSelector } from "../../components/languageSelector/LanguageSelector";
import { ICustomerStore } from "../../interfaces";
import { useCustomerStore } from "../../store/customerStore";
import { emailRegex } from "../../utils/ExpRegex";
import { HeaderLogin } from "./components/HeaderLogin";
import { VerificationCode } from "./components/VerificationCode";
import Slider from "../../components/slider/Slider";
import { useNavigate } from "react-router-dom";
import { Alert } from "../../components/alert/Alert";
import Icon from "@mdi/react";
import { Trans } from "react-i18next";
import { LinkText } from "../../components/linkText/LinkText";
import { t } from "i18next";
import { LoginGoogle } from "../../components/LoginGoogle/LoginGoogle";
import AppleSignin from "react-apple-signin-auth";
import { useDataLayer } from "../../hooks/useDataLayer";
import { sendEmailVerification, signInApple } from "../../services";

const buttonStylesDefault = {
  standard:
    "bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold text-white hover:bg-gradient-to-l from-magenta to-magenta-900",
  default: "bg-neutral-200 text-neutral-400 ",
  outline:
    "rounded-xl px-5 py-3 border-2 flex gap-2 items-center justify-center transition-all duration-300 h-btn w-full font-medium text-base text-neutral-charcoal",
};

export const Login = () => {
  const {
    language,
    isOpenMenuLanguage,
    toggleMenuLanguage,
    emailLocalStorage,
    setEmailLocalStorage,
  } = useCustomerStore((store: ICustomerStore) => store);

  const [email, setEmail] = useState<string>(emailLocalStorage);
  const [isChecked, setIsChecked] = useState<boolean>(
    emailLocalStorage.length > 0
  );
  const [isVerification, setIsVerification] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const { setDataLayer } = useDataLayer();
  const navigate = useNavigate();

  const handleChange = (value: string) => {
    value.length <= 150 && setEmail(value);
  };
  const hideVerificationCode = () => {
    setIsVerification(false);
    setEmail("");
  };

  const handleNavigateToHelp = () => navigate("/helpCenter");

  const onChecked = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.currentTarget.checked);
    e.currentTarget.checked
      ? setEmailLocalStorage(email ?? "")
      : setEmailLocalStorage("");
  };

  const handlePushLoginEvent = (customerId: string) => {
    setDataLayer({
      event: "login",
      userId: customerId,
    });
  };

  const onSuccessAppleSignIn = async (event: any) => {
    const { data, error } = await signInApple(event);

    if (error) {
      return setError(t("global.login.errors.apple"));
    }
    navigate(`/${data.customerId}`, { replace: true });
    handlePushLoginEvent(data.customerId);
  };

  const handleSendVerification = () => {
    if (!emailRegex.test(email)) {
      setIsValid(false);
      return;
    }

    sendEmailVerification({ email, language });
    setIsVerification(true);
    setIsValid(true);
  };

  useEffect(
    function saveEmailOnLocalStorage() {
      if (isChecked && email.length > 5) {
        setEmailLocalStorage(email);
      }
    },
    [email, isChecked, setEmailLocalStorage]
  );

  return (
    <>
      {!isVerification ? (
        <div className="bg-white h-full md:h-screen w-full py-12 md:px-16 px-6 flex justify-center items-center">
          <div className="flex flex-row h-full gap-8 justify-between items-center lg:w-[1224px] ">
            <div className="flex flex-col w-full relative">
              <HeaderLogin />
              <div className="md:mt-[50px] mt-6" />
              {error && (
                <Alert
                  type="custom"
                  customStyle="w-full md:my-10 absolute md:top-14 -top-4 h-16 bg-red-100 border-red-300 rounded-xl flex flex-row p-6 gap-2 flex flex-row items-center"
                >
                  <Icon
                    path={mdiAlertCircleOutline}
                    size={0.7}
                    className="text-semantic-error-600"
                  />
                  <p className="text-sm font-normal text-semantic-error-600">
                    {error}
                  </p>
                </Alert>
              )}

              <div className="flex flex-col">
                <div className="flex flex-col md:items-start items-center gap-2">
                  <p className="text-neutral-charcoal text-base font-normal">
                    {t("global.login.title1")}
                  </p>

                  <p className="text-black text-lg font-bold md:text-start text-center">
                    {t("global.login.title2")}
                  </p>
                </div>

                <div className="flex flex-col md:gap-6 gap-5 md:mt-3 mt-8">
                  <div className="flex flex-col gap-2">
                    <FloatingLabelInput
                      label={t("global.login.label")}
                      name="username"
                      type="text"
                      value={email}
                      error={!isValid}
                      errorMessage={t("global.login.errors.validFormatEmail")}
                      onChange={handleChange}
                    />

                    <div className="flex flex-row gap-2 items-center">
                      <input
                        // disabled={!isValid}
                        className="border-neutral-400 border rounded-[4px] appearance-none w-4 h-4 checked:focus:bg-slate-200 checked:bg-check bg-no-repeat bg-contain"
                        type={"checkbox"}
                        checked={isChecked}
                        onChange={onChecked}
                      />
                      <p className="text-neutral-600 text-sm font-bold">
                        {t("global.login.checkRemember")}
                      </p>
                    </div>
                  </div>

                  <HButton
                    type="button"
                    name="button login"
                    className={`h-btn w-full h-12 px-5 py-3 rounded-2xl font-medium text-base flex items-center justify-center ${
                      email.length > 5
                        ? buttonStylesDefault.standard
                        : buttonStylesDefault.default
                    }`}
                    disabled={email.length < 5}
                    text={t("global.login.btnLogin")}
                    onClick={handleSendVerification}
                  />
                </div>

                <div className="flex flex-row items-center gap-2 justify-center md:my-8 my-6">
                  <div className="bg-neutral-300 rounded-[1px] h-0 border-b w-full" />
                  <p className=" text-center w-full">
                    {t("global.login.withContinue")}
                  </p>
                  <div className="bg-neutral-300 rounded-[1px] h-0 border-b w-full" />
                </div>
              </div>

              <div className="flex flex-col h-full  justify-between">
                <div className="flex flex-col  gap-4">
                  <div className="flex flex-row gap-2">
                    <AppleSignin
                      authOptions={{
                        clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
                        scope: "email name",
                        redirectURI: process.env.REACT_APP_APPLE_REDIRECT,
                        state: "",
                        nonce: "nonce",
                        usePopup: true,
                      }}
                      onSuccess={onSuccessAppleSignIn}
                      onError={(error) => console.error(error)}
                      render={(props) => (
                        <HButton
                          {...props}
                          containerStyles="w-full"
                          className={buttonStylesDefault.outline}
                          icon={mdiApple}
                          text={t("global.login.btnApple")}
                          isDefaultStyles
                        />
                      )}
                    />

                    <LoginGoogle
                      setError={setError}
                      handlePushLoginEvent={handlePushLoginEvent}
                    />
                  </div>
                  <HButton
                    type="button"
                    name="button need help"
                    className="flex gap-2 flex-row justify-center items-center h-12 border-none text-neutral-charcoal text-base font-medium"
                    icon={mdiFaceAgent}
                    text={t("global.login.btnNeedHelp")}
                    onClick={handleNavigateToHelp}
                  />
                </div>

                <p className="text-neutral-500 text-xs font-normal text-center md:mt-10">
                  <Trans
                    i18nKey="global.login.termsAndPolitics"
                    components={{
                      p1: (
                        <LinkText
                          to={t("global.login.linkTerms")}
                          className="font-bold underline"
                        />
                      ),
                      p2: (
                        <LinkText
                          to={t("global.login.linkPolitics")}
                          className="font-bold underline"
                        />
                      ),
                    }}
                  />
                </p>
              </div>
            </div>
            <div className="w-full items-center lg:flex justify-center hidden">
              <Slider />
            </div>
          </div>
        </div>
      ) : (
        <VerificationCode
          hideVerificationCode={hideVerificationCode}
          email={email}
          setError={setError}
        />
      )}
      {isOpenMenuLanguage && (
        <LanguageSelector
          handleChangeVisible={() => toggleMenuLanguage(false)}
        />
      )}
    </>
  );
};
