import { createRef, useEffect, useRef, useState } from "react";
import { OptionType } from "./PendingMobile";

interface InstallOptionsProps {
  options: IOptions[];
  selectedOption: OptionType;
  handleChangeOption: (option: OptionType) => void;
}
export interface IOptions {
  option: OptionType;
  label: string;
  icon?: JSX.Element;
}
export const InstallOptions = ({
  handleChangeOption,
  options,
  selectedOption,
}: InstallOptionsProps) => {
  const activeStyles = "border border-[rgb(41,43,46)] font-bold";
  const [indexOption, setIndexOption] = useState<number>(0);
  const optionRefs = useRef<Array<React.RefObject<HTMLDivElement>>>(
    options.map(() => createRef<HTMLDivElement>())
  );

  const handleChangeOptionWithScroll = (option: OptionType, idx: number) => {
    handleChangeOption(option);
    setIndexOption(idx);
  };

  useEffect(() => {
    if (indexOption !== null) {
      const selectedRef = optionRefs.current[indexOption];
      if (selectedRef && selectedRef.current) {
        selectedRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [selectedOption]);

  return (
    <div className="w-full max-w-[400px]  h-auto overflow-x-auto scrollbar-hide  py-2 flex gap-2 items-center">
      {options.map((option, idx) => (
        <article
          ref={optionRefs.current[idx]}
          onClick={() => handleChangeOptionWithScroll(option.option, idx)}
          key={idx}
          className={`${
            selectedOption === option.option && activeStyles
          }  w-full  flex justify-center items-center rounded-[40px] gap-2 px-[20px] py-2`}
        >
          {option.icon && option.icon}
          <p className="{w-full inline-block whitespace-nowrap">
            {option.label}
          </p>
        </article>
      ))}
    </div>
  );
};
