import { MenuLanguage } from "../../../components/menus/MenuLanguage";
import { IHeaderLogin } from "../../../interfaces";

import logo from "../../../assets/Holafly_Logo.svg";
import HolaflyLogo from "../../../h_logo.svg";

export const HeaderLogin = ({
  isHeaderResponsive,
}: IHeaderLogin): JSX.Element => {
  const isResponsive: boolean = isHeaderResponsive ? isHeaderResponsive : false;

  return (
    <div
      data-testid="header-login"
      className={`flex md:flex-row justify-between items-center ${
        isResponsive ? "" : "flex-col"
      }  gap-4`}>
      {isHeaderResponsive && (
        <img
          data-testid="logo-mobile"
          draggable={false}
          className="w-[160px] block md:hidden"
          src={HolaflyLogo}
          alt="logo_Holafly"
        />
      )}

      <img
        data-testid="logo-desktop"
        draggable={false}
        className={`${isResponsive ? "hidden md:block" : ""} w-[160px]`}
        src={logo}
        alt="logo_Holafly"
      />

      <MenuLanguage />
    </div>
  );
};
