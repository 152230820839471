import axios from "axios";
import { API_URLS, initialCustomerProfile } from "../../../constants";

export const postUpdatedUserProfile = async (id: string, body: any) => {
  const { URL_UPDATE_PROFILE_USER } = API_URLS;
  try {
    const { status, data } = await axios.post(
      URL_UPDATE_PROFILE_USER(id),
      body
    );

    if (status === 200) {
      return { data, status, loading: false, isCompleted: true };
    }

    return {
      data: initialCustomerProfile,
      status,
      loading: false,
      isError: true,
    };
  } catch (error) {
    return {
      data: initialCustomerProfile,
      status: 500,
      loading: false,
      isError: true,
    };
  }
};
