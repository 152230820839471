import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { mdiPlusCircleOutline } from "@mdi/js";
import HButton from "../common/hbutton/HButton";
import { FlagCountry } from "../common/flag/FlagCountry";
import { useCustomerStore } from "../../store/customerStore";
import { ICardStateProps, ICustomerStore } from "../../interfaces";
import { toUTCDate, isProviderActive } from "../../utils";

export const CardStateLite = ({
  status,
  data,
  handleEvent,
}: ICardStateProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { language } = useCustomerStore((store: ICustomerStore) => store);

  const expiredDate = data.deactivation_date
    ? format(toUTCDate(data.deactivation_date!), "dd MMM yyyy")
    : "";

  const handleNavigateToDetails = () => navigate(`details/${data.iccid}`);

  return (
    <div
      data-testid="card-state"
      className="relative flex flex-col  hover:shadow-card transition-all duration-300 border border-[#E4E6EA] rounded-xl w-full md:w-[323px]  max-h-full"
    >
      {status === "ACTIVE" && (
        <div className="flex justify-center items-center text-[12px] font-normal text-[#56575A] bg-[#F8F8FB] w-full h-[32px] rounded-t-xl">
          Expires on {expiredDate}
        </div>
      )}
      <div className="w-full h-full p-[20px] flex flex-col gap-[20px]">
        <article className="flex flex-col w-full gap-[8px]">
          <FlagCountry state={status} flagImg={data.flagUrl || ""} />
          <h2 className="font-bold text-base text-neutral-charcoal whitespace-normal break-words break-all">
            {data.destination[language]}
          </h2>
          <p className="whitespace-normal break-words break-all font-normal text-sm text-neutral-charcoal">
            {data.boundle[language]}
          </p>
        </article>
        {status === "ACTIVE" ? (
          <div className="flex flex-col gap-[10px]">
            {isProviderActive(data.provider_name) && (
              <HButton
                type="button"
                name="button add more data"
                containerStyles="rounded-xl bg-digital-green hover:bg-brand-green-500 active:bg-semantic-success-400"
                text={t("global.customerV2.home.buttons.buttonAddMoreData")}
                className="gap-2 h-12 flex items-center justify-center px-5 py-3 text-neutral-charcoal border-none hover:text-white font-medium"
                icon={
                  <Icon
                    data-testid="icon"
                    path={mdiPlusCircleOutline}
                    size={1}
                    className="hover:text-white"
                  />
                }
                onClick={() => handleEvent(data)}
              />
            )}
            <HButton
              type="button"
              name="button view details"
              containerStyles="border border-neutral-300 rounded-xl bg-white hover:bg-neutral-300 transition-all duration-300"
              text={t("global.customerV2.home.buttons.buttonViewDetails")}
              className="h-12 flex items-center justify-center px-5 py-3 border-none font-medium"
              onClick={handleNavigateToDetails}
            />
          </div>
        ) : (
          <HButton
            type="button"
            name="button install and activate"
            containerStyles="mt-auto rounded-xl bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold transition-all duration-300"
            text="Install and activate"
            className="h-12 flex items-center justify-center px-5 py-3 text-white border-none font-medium"
            onClick={handleNavigateToDetails}
          />
        )}
      </div>
    </div>
  );
};
