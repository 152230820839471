import { useState } from "react";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import Icon from "@mdi/react";
import { IDropdownSelect } from "../../interfaces";

export const DropdownSelect = ({
  value,
  options,
  handleSelectedOption,
  selectOption,
  width,
}: IDropdownSelect): JSX.Element => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleDropdown = () => setOpenDropdown(!openDropdown);

  const widthDropdown = width ? width : `w-full md:w-[322.67px]`;

  return (
    <div
      data-testid="dropdown-select"
      className={`flex flex-col ${widthDropdown} gap-2 relative h-10`}>
      <div
        data-testid="dropdown-select-toggle"
        onClick={handleDropdown}
        className={`absolute z-10 border bg-white border-neutral-300 ${widthDropdown} py-1 px-3 h-10 rounded-lg`}>
        <div className="flex flex-row justify-between w-full items-center h-full">
          <p className="text-neutral-charcoal font-medium text-base">{value}</p>

          <Icon
            className="transition-all duration-300"
            path={openDropdown ? mdiChevronUp : mdiChevronDown}
            size={0.8}
          />
        </div>
      </div>

      {openDropdown && (
        <div
          className={`absolute z-10 top-11 shadow-lg transition-all duration-300  border bg-white border-neutral-300 ${widthDropdown} h-auto p-2 rounded-lg flex flex-col gap-1`}>
          {options.map((option) => {
            return (
              <button
                key={option.id}
                data-testid={`btn-option-${option.id}`}
                onClick={() => handleSelectedOption(option)}
                className={`border ${
                  selectOption.id === option.id
                    ? "border-neutral-charcoal bg-neutral-200 transition-all duration-300"
                    : "border-transparent bg-white"
                } w-full rounded-lg p-2`}>
                <p className="text-neutral-charcoal text-base font-normal text-left">
                  {option.value}
                </p>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};
