import { useEffect, useMemo, useState } from "react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import HButton from "../common/hbutton/HButton";
import { BannerGalleryProps } from "../../interfaces";
import "./BannerGallery.css";
import { t } from "i18next";
import { Trans } from "react-i18next";

export const BannerGallery = ({ elements }: BannerGalleryProps): JSX.Element => {
  const [size,setSize] = useState(true)
  const [currentSlideAnimation, setCurrentSlideAnimation] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Client-side-only code
      setSize(window.innerWidth <= 425);
    }
  }, []);

  useEffect(() => {
    const handleResize = (e: any) => {
      setSize(e.target.innerWidth <= 425);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [size]);

  const PAGE_SIZE: number = size  ? 2 :3;
  const totalPages = Math.max(0, elements.length - PAGE_SIZE + 1);

  const currentGalleryData = useMemo(() => {
    const startIndex = currentPage;
    const endIndex = Math.min(startIndex + PAGE_SIZE, elements.length);
    return elements.slice(startIndex, endIndex);
  }, [currentPage, elements,PAGE_SIZE]);

  const handleBackPage = (): void => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      setCurrentSlideAnimation("animate-slideRight");
    }
  };

  const handleNextPage = (): void => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setCurrentSlideAnimation("animate-slideLeft");
    }
  };

  useEffect(() => {
    const timeIntervalAnimation = setTimeout(() => {
      setCurrentSlideAnimation("");
    }, 600);

    return () => clearTimeout(timeIntervalAnimation);
  }, [currentPage]);

  return (
    <div className="relative mt-5 pt-2 w-full h-[250px] lg:h-[270px] flex flex-col justify-start items-center overflow-y-hidden">
      <div
        id="scroll-container"
        className={`lg:overflow-hidden transition-all duration-300 pb-5 w-full gap-[18px] flex justify-start items-start max-w-[1016px] overflow-y-hidden overflow-x-auto`}
      >
        {currentGalleryData.map(({ id, img, link, isLinkTranslate, text, handleClick }) => (
          <a
            href={isLinkTranslate ? t(link) : link}
            target="_blank"
            rel="noreferrer"
            key={`${id}`}
            onClick={handleClick}
            className={`${currentSlideAnimation} relative cursor-pointer min-w-[253px] w-[253px] lg:min-w-[323px] lg:w-full h-[180px] lg:h-[212px] bg-fuchsia-100 rounded-xl transform transition-transform duration-500`}
          >
            <img
              draggable="false"
              className="h-full w-full object-cover rounded-xl"
              src={img}
              alt="Learn"
            />
            <p className="text-[14px] lg:text-[16px] absolute bottom-[24px] lg:bottom-[30px] w-[72%] text-center right-0 left-0 ml-auto mr-auto">
              <Trans
                i18nKey={`${text}`}
                components={{
                  strong: <strong />,
                }}
              />
            </p>
          </a>
        ))}
      </div>

      {totalPages > 1 && (
        <div
          data-testid="buttons-group-gallery"
          className="w-auto absolute flex gap-4 justify-center items-end bottom-0 right-0 left-0 ml-auto mr-auto"
        >
          <HButton
            type="button"
            name="button previous banner gallery"
            className={`flex justify-center items-center border border-slate-20000 rounded-full w-[32px] h-[32px] ${
              currentPage === 0 ? "opacity-50" : "opacity-100"
            }`}
            icon={mdiChevronLeft}
            disabled={currentPage === 0}
            onClick={handleBackPage}
          />

          <HButton
            type="button"
            name="button next banner gallery"
            className={`flex justify-center items-center border border-slate-20000 rounded-full w-[32px] h-[32px] ${
              currentPage === totalPages - 1 ? "opacity-50" : "opacity-100"
            }`}
            icon={mdiChevronRight}
            disabled={currentPage === totalPages - 1}
            onClick={handleNextPage}
          />
        </div>
      )}
    </div>
  );
};;