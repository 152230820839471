import axios from "axios";
import { API_URLS, initialCustomerProfile } from "../../../constants";

export const getUserProfile = async (id: string) => {
  const { URL_GET_PROFILE_USER } = API_URLS;
  try {
    const { status, data } = await axios.get(URL_GET_PROFILE_USER(id));

    if (status === 200) {
      return { data, status, loading: false };
    }

    return { data: initialCustomerProfile, status, loading: false };
  } catch (error) {
    return { data: initialCustomerProfile, status: 500, loading: false };
  }
};
