import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { postSignInWithGoogle } from "../services";
import { CodeResponse, useGoogleLogin } from "@react-oauth/google";
import { IResponseUseSignInGoogle, IUseSignInGoogle } from "../interfaces";
import { setToken } from "../utils";

export const useSignInGoogle = ({
  pushEventLogin,
  setError,
}: IUseSignInGoogle): IResponseUseSignInGoogle => {
  const navigate = useNavigate();

  const handleLoginWithGoogle = async ({ code }: CodeResponse) => {
    const { data, isError } = await postSignInWithGoogle(code);

    if (isError) {
      setError(t("global.login.errors.google"));
      return;
    }

    setToken(data.token);
    localStorage.setItem("customerId", data.customerId);
    navigate(`/${data.customerId}`, { replace: true });
    pushEventLogin(data.customerId);
  };

  const handleAuthGoogle = useGoogleLogin({
    flow: "auth-code",
    onSuccess: handleLoginWithGoogle,
  });

  return {
    handleAuthGoogle,
  };
};
