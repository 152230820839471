import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { mdiArrowLeft } from "@mdi/js";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { Input } from "../../../components/input/Input";
import { HeaderLogin } from "./HeaderLogin";
import { TermsAndPoliticsTextLink } from "../../../components/termsAndPolitics/TermsAndPoliticsTextLink";
import HButton from "../../../components/common/hbutton/HButton";
import { useCustomerStore } from "../../../store/customerStore";
import { postEmailVerification, postSignIn } from "../../../services";
import { ICustomerStore, IVerificationCode } from "../../../interfaces";
import { setToken } from "../../../utils";

export const VerificationCode = ({
  email,
  hideVerificationCode,
  setError,
}: IVerificationCode) => {
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [loader, setLoader] = useState(false);
  const [timer, setTimer] = useState<number>(30);

  const { language } = useCustomerStore((store: ICustomerStore) => store);
  const navigate = useNavigate();

  const customWindow: any = window;

  const signIn = async () => {
    setLoader(true);

    const { data, status, isLoading } = await postSignIn(
      email,
      verificationCode
    );

    if (status === 200) {
      setToken(data.token)
      localStorage.setItem("customerId", data.customerId);
      navigate(`/${data.customerId}`, { replace: true });
      customWindow.dataLayer.push({
        event: "login",
        userId: data.customerId,
      });
    } else if (status === 500) {
      if (data.blocked) {
        setError(t("global.login.errors.moreAttemps"));
      } else {
        setError(t("global.login.errors.codeIncorrect"));
      }
      hideVerificationCode();
      setLoader(isLoading);
    } else {
      setError(t("global.login.errors.internalError"));
      hideVerificationCode();
      setLoader(isLoading);
    }
  };

  const isDisabledVerificationCode = verificationCode.length < 1;
  const buttonTextVerificationCode = loader ? "" : t("global.login.btnLogin");
  const buttonClassVerificationCode =
    verificationCode.length > 0
      ? !loader
        ? "bg-gradient-to-r from-magenta to-magenta-900 hover:from-magenta-bold hover:to-magenta-bold text-white"
        : "bg-neutral-200 text-neutral-400"
      : "bg-neutral-200 text-neutral-400";

  const loaderIconVerificationCode = loader ? (
    <div
      className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-gray-300 border-r-gray-600 align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
      role="status"
    />
  ) : (
    <></>
  );

  const handleSendVerification = () => {
    postEmailVerification(email, language);
    setTimer(30);
  };

  const decrementTimer = useCallback(() => {
    setTimer((prevTimer) => prevTimer - 1);
  }, []);

  useEffect(() => {
    let intervalId: null | NodeJS.Timer = null;
    if (timer > 0) {
      intervalId = setInterval(decrementTimer, 1000);
    }
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [timer, decrementTimer]);

  return (
    <div
      data-testid="verification-code"
      className="relative flex flex-col h-screen w-full py-12 md:px-16 px-6 bg-white"
    >
      <HeaderLogin isHeaderResponsive />

      <div className="md:mt-[50px] mt-6" />

      <HButton
        type="button"
        name="button go back"
        className="h-12 w-12 p-3 flex justify-center items-center border rounded-xl border-neutral-300 hover:bg-slate-50"
        icon={mdiArrowLeft}
        onClick={hideVerificationCode}
      />

      <div className="mt-5">
        <h1 className="my-3 font-medium md:text-[28px] text-[22px]">
          {t("global.login.verficationCode.title")}
        </h1>

        <div className="flex flex-col mb-4 text-neutral-charcoal md:text-base text-sm">
          <p>
            <Trans
              i18nKey="global.login.verficationCode.description"
              components={{
                p: <span className="text-black font-bold" />,
              }}
              values={{ email }}
            />
          </p>
          <span className="text-neutral-500">
            {t("global.login.verficationCode.span")}
          </span>
        </div>

        <p className="text-neutral-charcoal md:text-base text-sm">
          {t("global.login.verficationCode.reminderText")}
        </p>
      </div>

      <div className="my-6">
        <Input
          handleChange={(e) => {
            e.target.value.length <= 5 && setVerificationCode(e.target.value);
          }}
          value={verificationCode}
          label={t("global.login.verficationCode.textCodVerification")}
          name="code"
          type={"password"}
        />
      </div>

      <HButton
        onClick={signIn}
        name="button verification code"
        type="button"
        disabled={isDisabledVerificationCode}
        className={`${buttonClassVerificationCode} rounded-2xl py-3 px-5 w-[272px] h-12 border-none text-base font-medium outline-none`}
        text={buttonTextVerificationCode}
        icon={loaderIconVerificationCode}
      />

      <div className="mt-11 flex flex-col justify-between h-full">
        <div className="flex flex-col">
          <p className="text-base font-normal">
            {t("global.login.verficationCode.messageNoCode")}
          </p>

          <div
            className={`mt-6 flex flex-row ${
              timer > 0 ? "gap-4" : ""
            } items-center`}
          >
            <p className="text-xs font-bold text-neutral-charcoal">
              {timer > 0 && `00:${timer}`}
            </p>
            <HButton
              type="button"
              name="button resend code verification"
              disabled={timer > 0}
              onClick={handleSendVerification}
              className={`font-bold text-[12px] border-none p-0  ${
                timer <= 0
                  ? "text-semantic-info-400 capitalize"
                  : "text-neutral-400 uppercase"
              }`}
              text={t("global.login.verficationCode.btnReSend")}
            />
          </div>
        </div>

        <TermsAndPoliticsTextLink />
      </div>
    </div>
  );
};
