import React, { CSSProperties } from "react";
import Icon from "@mdi/react";
import { mdiEmailOutline } from "@mdi/js";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share";
import { FacebookLight, LinkedinLight, XTwitter } from "../svg";

import arrow from "../../assets/Arrow_black.svg";

import "./style.css";

type Props = {
  arrowPosition: "top" | "bottom" | "left" | "right";
  customStyle?: CSSProperties;
  className?: string;
};

type socialNetworks = "facebook" | "x" | "linkedin" | "mail";

export const TooltipListShareReferrer: React.FC<Props> = ({
  arrowPosition,
  customStyle,
  className,
}) => {
  const tooltipStyle = {
    top: arrowPosition === "bottom" ? "calc(100% + 10px)" : "",
    bottom: arrowPosition === "top" ? "calc(100% + 10px)" : "",
    left: arrowPosition === "right" ? "calc(50% + 10px)" : "",
    right: arrowPosition === "left" ? "calc(50% + 10px)" : "",
  };

  const styleEffect = (): React.CSSProperties => {
    switch (arrowPosition) {
      case "top":
        return {
          top: "-12px",
          rotate: "0deg",
        };
      case "bottom":
        return {
          bottom: "-12px",
          rotate: "180deg",
        };

      case "right":
        return {
          right: "-14px",
          bottom: "calc(50% - 12px)",
          rotate: "90deg",
        };

      case "left":
        return {
          left: "-14px",
          bottom: "calc(50% - 12px)",
          rotate: "-90deg",
        };

      default:
        return {};
    }
  };

  const customWindow: any = window;

  const handleEventShareSocialNetworks = (socialNetwork: socialNetworks) => {
    customWindow.dataLayer.push({
      event: "custom_event",
      event_name: "aff_referral_shared",
      socialNetwork,
    });
  };

  return (
    <div
      data-testid="tooltip-list-share-referrer"
      style={{ ...customStyle }}
      {...(className ? { className } : "")}
    >
      <div className="shadow-card lg:flex rounded-lg flex-col items-center w-[160px] m-auto absolute right-[calc(50%-(-42px))] md:right-[calc(50%-80px)] lg:right-[calc(24%-100px)]">
        <div
          className={`w-[160px] min-h-[52px] h-full border bg-neutral-charcoal rounded-lg px-3 py-[10px] ${tooltipStyle}`}
        >
          <ul className="flex flex-col">
            <FacebookShareButton url={"https://esim.holafly.com/es"}>
              <div
                onClick={() => handleEventShareSocialNetworks("facebook")}
                className="flex items-center gap-4 p-2 h-full"
              >
                <section className="w-[16px] h-[16px] ">
                  <FacebookLight color="white" />
                </section>
                <p className="text-white font-medium text-sm">Facebook</p>
              </div>
            </FacebookShareButton>

            <TwitterShareButton
              url={"https://esim.holafly.com/es"}
              title="Discount"
            >
              <div
                onClick={() => handleEventShareSocialNetworks("x")}
                className="flex items-center gap-4 p-2 h-full"
              >
                <XTwitter />
                <p className="text-white font-medium text-sm  text-left">X</p>
              </div>
            </TwitterShareButton>

            <LinkedinShareButton url={"https://esim.holafly.com/es"}>
              <div
                onClick={() => handleEventShareSocialNetworks("linkedin")}
                className="flex items-center gap-4 p-2 h-full"
              >
                <LinkedinLight color="white" />
                <p className="text-white font-medium text-sm">Linkedin</p>
              </div>
            </LinkedinShareButton>

            <EmailShareButton url={"https://esim.holafly.com/es"}>
              <div
                onClick={() => handleEventShareSocialNetworks("mail")}
                className="flex items-center gap-4 p-2 h-full"
              >
                <Icon path={mdiEmailOutline} size={0.7} color={"white"} />
                <p className="text-white font-medium text-sm">Mail</p>
              </div>
            </EmailShareButton>

            {/* <li className="flex items-center gap-4 p-2 h-full">
              <Icon path={mdiLink} size={0.8} color={"white"} />
              <p className="text-white font-medium text-sm">Copy link</p>
            </li> */}
          </ul>
        </div>

        <div
          style={styleEffect()}
          className="absolute h-5 w-6 right-2 lg:right-16"
        >
          <img className="h-5 w-10" src={arrow} alt="arrow" />
        </div>
      </div>
    </div>
  );
};
