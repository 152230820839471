import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DropdownSelect } from "../../components/dropdowns/DropdownSelect";
import { LayoutPageWithMapBackground } from "../../components/layouts/LayoutPageWithMapBackground";
import { UniversalPagination } from "../../components/paginations/UniversalPagination";
import { InvoiceSkeleton } from "./components/skeletons/InvoiceSkeleton";
import { useDownloadPdfInvoice, useUserOrders } from "../../hooks";
import { IOptDropdownSelect, ICustomerStore } from "../../interfaces";
import { useCustomerStore } from "../../store/customerStore";
import { ORDEN_OPTIONS, PER_PAGE_OPTIONS } from "../../constants";
import { ErrorBoundary } from "../../components/errors/ErrorBoundaries";
import { ReceiptStatusMessage } from "../../components/alert/ReceiptStatusMessage";
import { NotificationInternalError } from "../../components/alert/NotificationInternalError";
import { PreviewInvoice } from "./components/PreviewInvoice";
import { AlertNotification } from "../../components/alert/AlertNotification";

const classNameAlertNotification =
  "mt-20 fixed p-5 rounded-xl z-10 mx-2 transition-all duration-300 animate-slideDown flex self-center";

const InvoicePage = (): JSX.Element => {
  const { customerId } = useCustomerStore((store: ICustomerStore) => store);
  const { t } = useTranslation();

  const {
    orders: { orders, pages_count },
    loading,
    isError,
    perPage,
    sort,
    updatePage,
    updatePerPage,
    updateSort,
    refreshOrders,
  } = useUserOrders(customerId);

  const {
    fetchDownloadPdf,
    loading: loadingPdf,
    hasError,
    message,
    showAlert,
  } = useDownloadPdfInvoice();

  const handleSelectedOption = (option: IOptDropdownSelect) =>
    updateSort(option.id as string);

  const handleSelectedLimitCard = (option: IOptDropdownSelect) =>
    updatePerPage(Number(option.value));

  const optionSort = ORDEN_OPTIONS.find((opt) => opt.id === sort)!;
  const optionPerPage = PER_PAGE_OPTIONS.find(
    (opt) => opt.value === perPage.toString()
  )!;

  // result contendrá el objeto con la información del error o será vació si no hay error.
  const invoiceErrorMessage =
    !orders.length && !isError ? "Error User" :  "Error Internal";

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <LayoutPageWithMapBackground
      title={t("global.customerV2.invoice.main.title")}>
      {loading ? (
        <InvoiceSkeleton />
      ) : (
        <ErrorBoundary
          fallBackComponent={
            invoiceErrorMessage === "Error User" ? (
              <ReceiptStatusMessage />
            ) : (
              <NotificationInternalError
                title="global.customerV2.invoice.alerts.alertConnectionInternalError.title"
                description="global.customerV2.invoice.alerts.alertConnectionInternalError.description"
                textButton="global.customerV2.invoice.alerts.alertConnectionInternalError.textButton"
                onClick={refreshOrders}
              />
            )
          }
          error={!orders.length || isError}>
          <div
            data-testid="invoice"
            className="relative w-full flex flex-col gap-6">
            <DropdownSelect
              value={`${t(
                "global.customerV2.invoice.main.dropdown.title"
              )}${`: ${optionSort.value}`}`}
              options={ORDEN_OPTIONS}
              handleSelectedOption={handleSelectedOption}
              selectOption={optionSort}
            />

            <div className="w-full flex gap-4 lg:gap-6 flex-wrap justify-center">
              {orders.map((invoice) => (
                <PreviewInvoice
                  isLoadingPdf={loadingPdf}
                  handleDownLoadPdf={fetchDownloadPdf}
                  key={invoice.order_name}
                  {...invoice}
                />
              ))}
            </div>

            <div className="flex flex-col gap-[30px] md:gap-0 md:flex-row justify-between items-center w-full">
              <div className="flex flex-row gap-4 items-center">
                <p className="font-medium text-base">{t("global.customerV2.invoice.main.listReceipts.title")}</p>

                <DropdownSelect
                  value={t(
                    "global.customerV2.invoice.main.listReceipts.textSelected",
                    {
                      number: perPage,
                    }
                  )}
                  options={PER_PAGE_OPTIONS}
                  handleSelectedOption={handleSelectedLimitCard}
                  selectOption={optionPerPage}
                  width="w-[146px]"
                />
              </div>

              <div className="flex flex-row items-center gap-4">
                <p className="font-medium text-base">{t("global.customerV2.invoice.main.pagination.title")}</p>
                <UniversalPagination
                  setInitialLimit={() => {}}
                  setCurrentPos={(currentPosition) =>
                    updatePage(currentPosition)
                  }
                  limitList={perPage}
                  numPagination={pages_count}
                />
              </div>
            </div>
          </div>
        </ErrorBoundary>
      )}
      {!loadingPdf && showAlert ? (
        <AlertNotification
          isVisible={showAlert}
          alertType={hasError ? "error" : "success"}
          message={message}
          customClassName={`${classNameAlertNotification} ${
            !hasError ? "bg-semantic-success-100" : "bg-semantic-error-100"
          }`}
        />
      ) : (
        <></>
      )}
    </LayoutPageWithMapBackground>
  );
};

export default InvoicePage;
